/* Accordian */

.accordion-container {
	margin: 2em auto 5em;

	.faq-title {
		font-size: 1.875em;
		margin-bottom: 1em;
	}

	a.accordion-toggle {
		font-family: $font-headline;
		position: relative;
		display: block;
		padding: 1em 1em 1em 3em;
		font-size: 1.25em;
		line-height: 1.125em;
		color: $color-black;
		text-decoration: none;
		border-bottom: 1px solid $color-black;
		margin: 10px 0 0 0;
		@include transition(all 0.2s ease);

    &:last-of-type {
      border: none;
    }

		span {
			display: block;
			padding: 0.5em 0;
			margin: 0 auto;
			text-align: center;
			color: $color-black;
			@include transition(all 0.2s ease);

			&.toggle-icon {
				// @include center-vert();
				position: absolute;
				top: 1.5em;
				left: 1em;
				display: block;
				color: $color-white;
				background-color: $color-secondary;
				padding: .5em;
    		font-size: .75em;
				margin-right: 1em;
			}
		}

		&:hover {
			color: $color-secondary;
		}

		&:hover > span {

		}

		&.open {
			color: $color-secondary!important;
		}

		&.open > span {

		}
	}

	.accordion-content {
		display: none;
		margin: 0;
		/*padding: 20px 20px 0 20px;
		overflow: auto;*/
	}

}
