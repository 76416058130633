/*
       _ _                     _   _
 _ __ / / | ___ _ __ ___  __ _| |_(_)_   _____
| '_ \| | |/ __| '__/ _ \/ _` | __| \ \ / / _ \
| |_) | | | (__| | |  __/ (_| | |_| |\ V /  __/
| .__/|_|_|\___|_|  \___|\__,_|\__|_| \_/ \___|
|_|

/*
|--------------------------------------------------------------------------
| Variables
| Author: P11 Interactive Team
| Visit us: @ p11.com
|--------------------------------------------------------------------------
|
| This file holds all of the variables for the site.
| 1. Fonts
| 2. Colors
| 3. Color Variations
| 4. Transparent Colors
| 5. Color List
| 6. Screen Sizes / Breakpoints
|
*/

/*
| 1. Fonts
| --------------------------------------------------
| These font variables are used to hold the values for
| the sitewide fonts used on the project.
|
*/

$font-main: 'gotham-book', sans-serif;
$font-main-bold: 'gotham-medium', sans-serif;
$font-main-light: 'gotham-light', sans-serif;
$font-headline: 'gotham-book', sans-serif;
$font-headline-bold: 'gotham-medium', sans-serif;
$font-headline-light: 'gotham-light', sans-serif;

$font-weight-light: 400;
$font-weight-regular: 400;
$font-weight-bold: 400;

/*
| 2. Colors
| --------------------------------------------------
| The variables are used to store the color theme for
| the site.
|
*/

$color-black: #000000;
$color-white: #ffffff;
$color-primary: #231f20;
$color-secondary: #57002a;
$color-tertiary: #58595b;
$color-surrogate: #c1c2c4;

/*
| 3. Color Variations
| --------------------------------------------------
| These color variations are auto generated by the
| following functions below.
*/

$color-primary-darkest: darken($color-primary, 10%);
$color-primary-dark: darken($color-primary, 5%);
$color-primary-light: lighten($color-primary, 5%);
$color-primary-lightest: lighten($color-primary, 10%);

$color-secondary-darkest: darken($color-secondary, 10%);
$color-secondary-dark: darken($color-secondary, 5%);
$color-secondary-light: lighten($color-secondary, 5%);
$color-secondary-lightest: lighten($color-secondary, 10%);

$color-tertiary-darkest: darken($color-tertiary, 10%);
$color-tertiary-dark: darken($color-tertiary, 5%);
$color-tertiary-light: lighten($color-tertiary, 5%);
$color-tertiary-lightest: lighten($color-tertiary, 10%);

$color-surrogate-darkest: darken($color-surrogate, 10%);
$color-surrogate-dark: darken($color-surrogate, 5%);
$color-surrogate-light: lighten($color-surrogate, 5%);
$color-surrogate-lightest: lighten($color-surrogate, 10%);


/*
| 6. Screen Sizes / Breakpoints
| --------------------------------------------------
| Breakpoint variables in pixel widths for media queries.
|
*/

/*
| Extra small mobile
*/
$screen-xs: 350px;

/*
| Mobile
*/
$screen-sm: 640px;

/*
| Tablet
*/
$screen-md: 768px;

/*
| Middle Sized Devices
*/
$screen-md-lg: 845px;

/*
| Small Laptop
*/
$screen-lg: 1024px;

/*
| Large Laptop / Desktop
*/
$screen-xlg: 1248px;

/*
| Large Desktop
*/
$screen-xxlg: 1500px;
